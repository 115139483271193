































import PDFOcorrenciaImunobiologicoForm from "./PDFOcorrenciaImunobiologicoForm.script";
import hmr from "vue2-hmr/dist";

export default hmr(PDFOcorrenciaImunobiologicoForm, module);
