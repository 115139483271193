import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import store from '../../store'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import { VueSelect } from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Messages from 'vue2-messages'
import fmt from '../../helpers/fmt'
import Unidade from '../../models/unidade'

interface UnidadeForm extends PageMixin {}

@Component({
    components: {'v-select': VueSelect},
    mixins: [PageMixin],
    store,
})
class UnidadeForm extends Vue implements UnidadeForm {
    fmt = fmt

    name = 'UnidadeForm';

    model = new Unidade();

    tiposUsuarios = {}
       
    idUnidade = null;
    idMunicipio = null;
    idRegional = null;
    idTipoHierarquiaEnvioPerdaFisica = null;
    municipios = [];
    regionais = [];
    unidades = [];
    tipoHierarquiaEnvioPerdaFisica = [];
    tipoUsuario = {};
    
    messages = {
        model: this.model,
    };

    change() {

    }

    computed(){

    }
    
    created() {
        this.idUnidade = this.$route.params.id == "new" ? null : this.$route.params.id
        if(this.idUnidade == null){
            this.model = new Unidade();
        }

        this.idRegional = null;
        this.idMunicipio = null;
        this.idTipoHierarquiaEnvioPerdaFisica = null;
        this.tipoUsuario = localStorage.getItem('tipoUsuario')
        if(this.tipoUsuario == "2"){
            this.model.municipio.regional.id = <null>localStorage.getItem('idRegional')
        }
        if(this.tipoUsuario == "3"){
            this.model.chvmnc = <null>localStorage.getItem('idMunicipio')
        }

        this.inicializarObjetos();
        this.getUnidade();
    }

    mounted() {

    }

    getUnidade(){
        if(this.idUnidade != null){
            this.http().get(`${this.serverUrl}/unidade/get-unidade/${this.idUnidade}`)
            .then(res => {
                if(res.data.erro){
                    this.$swal.fire({title: res.data.msg+' - Redirecionando para a listagem', text: '', icon: 'error', timer: 2700});
                    setTimeout(() => {
                        this.$router.push('/unidade');
                    } , 3000);
                    return;
                }
                this.model = res.data.unidade;
                if(this.model.enviarMunicipio){
                    this.idTipoHierarquiaEnvioPerdaFisica = 0;
                }
                if(this.model.enviarRegional){
                    this.idTipoHierarquiaEnvioPerdaFisica = 1;
                }
                if(!this.model.enviarMunicipio && !this.model.enviarRegional){
                    this.idTipoHierarquiaEnvioPerdaFisica = 2;
                }
            });
        }
    }

    inicializarObjetos(){
        this.http().get(`${this.serverUrl}/unidade/inicializar-objetos/`).then(resp => {
            this.regionais = resp.data.regionais;
            this.municipios = resp.data.municipios;
            this.tipoHierarquiaEnvioPerdaFisica = resp.data.tipoHierarquiaEnvioPerdaFisica;
        });
    }

    salvar() {
        if(this.model.nome == null || this.model.nome == ''){
            Messages.error('Informe o nome da Unidade');
            $("#nome").trigger('focus');
            return;
        }
        if(this.model.cnes == null || this.model.cnes == ''){
            Messages.error('Informe o CNES da Unidade');
            $("#cnes").trigger('focus');
            return;
        }
        if(this.model.chvmnc == null){
            Messages.error('Selecione o município');
            $("#municipio").trigger('focus');
            return;
        }
        if(this.idTipoHierarquiaEnvioPerdaFisica == null){
            Messages.error('Por favor informe pra quem a unidade precisará enviar.');
            $("#hierarquiaEnvioPerdaFisica").trigger('focus');
            return;
        }

        if(this.idTipoHierarquiaEnvioPerdaFisica == 0){
            this.model.enviarMunicipio = true;
        }else{
            this.model.enviarRegional = false;
        }
        if(this.idTipoHierarquiaEnvioPerdaFisica == 1){
            this.model.enviarRegional = true;
        }else{
            this.model.enviarMunicipio = false;
        }
        if(this.idTipoHierarquiaEnvioPerdaFisica == 2){
            this.model.enviarMunicipio = false;
            this.model.enviarRegional = false;
        }

        this.http().post(`${this.serverUrl}/unidade/salvar`, {
            model: this.model
         })
        .then(resp => {
            if(resp.data.error){
                Messages.error(resp.data.error)
                this.$swal.fire('Erro!', '', 'error')
                return;
            }
            if (resp.data != null) {
                if(this.$route.params.id == "new"){
                    // this.limpar();
                    var msg = "Dados Salvo com Sucesso!";
                }else{
                    var msg = "Dados Editados com Sucesso!";
                }
                Messages.success(msg)
                this.$swal.fire('Sucesso!', '', 'success')
                this.$swal.fire({title: 'Sucesso!', text: '', icon: 'success', timer: 1250});
                setTimeout(() => {
                    this.$router.push('/unidade');
                } , 1000);
            } else {
                Messages.success('Os dados não foram salvos!')
                this.$swal.fire('Erro!', '', 'error')
            }
        }).catch(() => {});
    }

    limpar(){
        this.model = new Unidade();
    }

    changeRegional(){
        this.model.chvmnc = null;
        if(this.tipoUsuario == "3"){
            this.model.chvmnc = <null>localStorage.getItem('idMunicipio')
        }
        this.unidades = [];
    }

    filtrarMunicipiosPorRegional(idRegional){
        if(idRegional == null){
            this.unidades = [];
        }
        let arrMunicipios = [];
        if(this.tipoUsuario == "3"){
            arrMunicipios = this.municipios.filter(municipio => {
                return municipio?.chvmnc == this.model.chvmnc;
            });
        }else{
            arrMunicipios = this.municipios.filter(municipio => {
                return municipio?.regional.id == idRegional;
            });
        }

        return arrMunicipios;
    }

}

export default UnidadeForm