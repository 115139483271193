import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import store from '../../store'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import { VueSelect } from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Messages from 'vue2-messages'
import fmt from '../../helpers/fmt'
import Laboratorio from '../../models/laboratorio'

interface LaboratorioForm extends PageMixin {}

@Component({
    components: {'v-select': VueSelect},
    mixins: [PageMixin],
    store,
})
class LaboratorioForm extends Vue implements LaboratorioForm {
    fmt = fmt

    name = 'LaboratorioForm';

    model = new Laboratorio();

    idLaboratorio = null
    tipoUsuario = {}
    
    messages = {
        model: this.model,
    };

    change() {

    }

    computed(){

    }
    
    created() {
        this.idLaboratorio = this.$route.params.id == "new" ? null : this.$route.params.id
        if(this.idLaboratorio == null){
            this.model = new Laboratorio();
        }
        this.getLaboratorio();
    }

    mounted() {

    }

    getLaboratorio(){
        if(this.idLaboratorio != null){
            this.http().get(`${this.serverUrl}/laboratorio-produtor/get-laboratorio/${this.idLaboratorio}`)
            .then(res => {
                if(res.data.erro){
                    this.$swal.fire({title: res.data.msg+' - Redirecionando para a listagem', text: '', icon: 'error', timer: 2700});
                    setTimeout(() => {
                        this.$router.push('/laboratorio');
                    } , 3000);
                    return;
                }
                this.model = res.data;
            });
        }
    }

    salvar() {
        if(this.model.sigla == null){
            Messages.error('É necessário informar a sigla do laboratório');
            $("#sigla").trigger('focus');
            return;
        }
        if(this.model.descricao == null){
            Messages.error('É necessário informar a descrição/nome do laboratório');
            $("#descricao").trigger('focus');
            return;
        }
        this.http().post(`${this.serverUrl}/laboratorio-produtor/salvar`, {
            model: this.model
         })
        .then(resp => {
            if(resp.data.error){
                Messages.error(resp.data.error)
                this.$swal.fire('Erro!', '', 'error')
                return;
            }
            if (resp.data != null) {
                if(this.$route.params.id == "new"){
                    // this.limpar();
                    var msg = "Dados Salvo com Sucesso!";
                }else{
                    var msg = "Dados Editados com Sucesso!";
                }
                Messages.success(msg)
                this.$swal.fire('Sucesso!', '', 'success')
                this.$swal.fire({title: 'Sucesso!', text: '', icon: 'success', timer: 1250});
                setTimeout(() => {
                    this.$router.push('/laboratorio');
                } , 1000);
            } else {
                Messages.success('Os dados não foram salvos!')
                this.$swal.fire('Erro!', '', 'error')
            }
        }).catch(() => {});
    }

    limpar(){
        this.model = new Laboratorio();
    }
}

export default LaboratorioForm