

















































































import DiluenteListing from "./DiluenteListing.script";
import hmr from "vue2-hmr/dist";

export default hmr(DiluenteListing, module);
