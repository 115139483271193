import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import store from '../../store'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import { VueSelect } from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Messages from 'vue2-messages'
import fmt from '../../helpers/fmt'
import Imunobiologico from '../../models/imunobiologico'

interface ImunobiologicoForm extends PageMixin {}

@Component({
    components: {'v-select': VueSelect},
    mixins: [PageMixin],
    store,
})
class ImunobiologicoForm extends Vue implements ImunobiologicoForm {
    fmt = fmt

    name = 'ImunobiologicoForm';

    model = new Imunobiologico();

    tiposImunobiologicos = [];
       
    idImunobiologico = null;
    tipoUsuario = {}
    
    messages = {
        model: this.model,
    };

    change() {

    }

    computed(){

    }
    
    created() {
        this.idImunobiologico = this.$route.params.id == "new" ? null : this.$route.params.id
        if(this.idImunobiologico == null){
            this.model = new Imunobiologico();
        }
        this.inicializarObjetos().then(() => {
            this.getImunobiologico();
        });
    }

    mounted() {

    }

    async getImunobiologico(){
        if(this.idImunobiologico != null){
            await this.http().get(`${this.serverUrl}/imunobiologico/get-imunobiologico/${this.idImunobiologico}`)
            .then(res => {
                if(res.data.erro){
                    this.$swal.fire({title: res.data.msg+' - Redirecionando para a listagem', text: '', icon: 'error', timer: 2700});
                    setTimeout(() => {
                        this.$router.push('/imunobiologico');
                    } , 3000);
                    return;
                }
                Vue.set(this, 'model', res.data);
            });
        }
    }

    async inicializarObjetos(){
        await this.http().get(`${this.serverUrl}/imunobiologico/inicializar-objetos/`).then(resp => {
            this.tiposImunobiologicos = resp.data.tiposImunobiologicos;
        });
    }

    salvar() {
        if(this.model.descricao == null){
            Messages.error('Por favor, preencha o campo descrição.');
            $("#descricao").trigger('focus');
            return;
        }
        if(this.model.idTipoImunobiologico == null){
            Messages.error('Por favor, selecione o tipo de imunobiológico.');
            $("#idTipoImunobiologico").trigger('focus');
            return;
        }
        this.http().post(`${this.serverUrl}/imunobiologico/salvar`, {
            model: this.model
         })
        .then(resp => {
            if(resp.data.error){
                Messages.error(resp.data.error)
                this.$swal.fire('Erro!', '', 'error')
                return;
            }
            if (resp.data != null) {
                if(this.$route.params.id == "new"){
                    // this.limpar();
                    var msg = "Dados Salvo com Sucesso!";
                }else{
                    var msg = "Dados Editados com Sucesso!";
                }
                Messages.success(msg)
                this.$swal.fire('Sucesso!', '', 'success')
                this.$swal.fire({title: 'Sucesso!', text: '', icon: 'success', timer: 1250});
                setTimeout(() => {
                    this.$router.push('/imunobiologico');
                } , 1000);
            } else {
                Messages.success('Os dados não foram salvos!')
                this.$swal.fire('Erro!', '', 'error')
            }
        }).catch(() => {});
    }

    limpar(){
        this.model = new Imunobiologico();
    }

}

export default ImunobiologicoForm