



























import DropdownMenu from "@innologica/vue-dropdown-menu";

export default {
  components: {
    "dropdown-menu": DropdownMenu,
  },

  name: "dropdown-tree",
  props: {
    node: Object,
    tipoUsuario: String
  },
  methods:{
    temPermissaoAcesso(listaPermissoes){
      for(var permissao of listaPermissoes){
        if(permissao == this.tipoUsuario){
          return true
        }
      }
      return false
    }
  }
};
