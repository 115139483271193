import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import store from '../../store'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import Messages from 'vue2-messages'
import { VueSelect } from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Pagination from '../../components/Pagination.vue'
import PerdaFisica from '../../models/perdaFisica'
import fmt from '../../helpers/fmt'
import HistoricoPerdaFisica from '../PerdaFisica/HistoricoPerdaFisica.vue'
import _ from 'lodash'

interface PerdaFisicaListing extends PageMixin { }

@Component({
    components: { 'v-select': VueSelect, 'paginacao': Pagination, HistoricoPerdaFisica },
    mixins: [PageMixin],
    store
})
class PerdaFisicaListing extends Vue implements PerdaFisicaListing {
    fmt = fmt

    name = 'PerdaFisicaListing'

    idMunicipio = null;
    idRegional = null;
    idUnidade = null;
    perdasFisicas = [];
    tipoUsuario = {};
    tiposOcorrencias = [];
    regionais = [];
    municipios = [];
    unidades = [];
    tipoStatusPerdaFisica = [];
    listaStatusPerdaFisica = [];
    inicializarObjetos = [];
    statusFiltro = null;
    dataHoraReabertura = null;
    motivoReabertura = null;
    idPerdaFisicaReabertura = null;

    model = new PerdaFisica();

    filtro = {
        idTipoOcorrencia: null,
        idTipoStatusPerdaFisica: null,
        idRegional: null,
        idMunicipio: null,
        idUnidade: null,
        dataOcorrenciaInicio: null,
        dataOcorrenciaFim: null
    }

    limit = 10
    limits = [10, 20, 50, 100]
    page = 1
    totalPages = 0
    pagination = {totalCount: 1}

    messages = {
        motivoReabertura: this.motivoReabertura,
    };

    pageChanged(page) {
        this.page = page
        this.filter(page);
    }

    limitSelect() {
        this.page = 1
        this.buscarPerdasFisicas();
    }

    created() {
        this.perdasFisicas = [];
        this.listaStatusPerdaFisica = [];

        this.inicializarDadosFiltros();
        this.setarDadosUsuarioLogado();
        this.limpar();
    }

    mounted() {

    }

    setarDadosUsuarioLogado() {
        this.tipoUsuario = localStorage.getItem('tipoUsuario')
        if (this.tipoUsuario == "2") {
            this.filtro.idRegional = localStorage.getItem('idRegional')
        }
        if (this.tipoUsuario == "3") {
            this.filtro.idMunicipio = localStorage.getItem('idMunicipio')
            this.filtrarUnidadePorMunicipio();
        }
        if (this.tipoUsuario == "4") {
            this.filtro.idUnidade = localStorage.getItem('idUnidade')
        }
    }

    filtrarMunicipiosPorRegional(idRegional) {
        if (idRegional == null) {
            this.unidades = [];
        }
        let arrMunicipios = this.municipios.filter(municipio => {
            return municipio?.regional.id == idRegional;
        });
        return arrMunicipios;
    }

    filtrarUnidadePorMunicipio() {
        this.unidades = [];
        Vue.set(this.filtro, 'idUnidade', null);
        if (this.filtro.idMunicipio != null) {
            return this.http().get(`${this.serverUrl}/unidade/listar-unidades/${this.filtro.idMunicipio}`).then(resp => {
                this.unidades = resp.data.unidades;
            });
        }
    }

    changeRegional() {
        this.filtro.idMunicipio = null;
        this.unidades = [];
    }

    inicializarDadosFiltros() {
        this.http().get(`${this.serverUrl}/perda-fisica/dados-filtros`)
            .then(res => {
                this.tiposOcorrencias = res.data.tiposOcorrencias;
                this.regionais = res.data.regionais;
                this.municipios = res.data.municipios;
                this.tipoStatusPerdaFisica = res.data.statusPerdaFisica;
            })
    }

    filter(page) {
        this.http().get(`${this.serverUrl}/perda-fisica/filter?filtros=${JSON.stringify(this.filtro)}&page=${page}&per-page=${this.limit}`)
            .then(res => {
                this.perdasFisicas = res.data.perdasFisicas
                this.pagination = res.data?.pagination
                this.totalPages = Math.ceil(this.pagination?.totalCount / this.limit)
                $('[data-toggle="tooltip"]').tooltip()

                // Extrair o valor de idTipoStatusPerdaFisica
                this.statusFiltro = this.filtro.idTipoStatusPerdaFisica;
            })
    }

    buscarPerdasFisicas() {
        return this.http().get(`${this.serverUrl}/perda-fisica/${this.page}/${this.limit}`);
    }

    limpar() {
        this.filtro = {
            idTipoOcorrencia: null,
            idTipoStatusPerdaFisica: null,
            idRegional: null,
            idMunicipio: null,
            idUnidade: null,
            dataOcorrenciaInicio: null,
            dataOcorrenciaFim: null
        };
        this.setarDadosUsuarioLogado();
        this.filter(1);
    }

    confirmarAcao(id, tipoAcao) {
        var mensagemFalha = "Ocorreu uma falha ao executar a ação";
        if (tipoAcao == 'excluir') {
            var mensagem = "Tem certeza que deseja Excluir essa Perda Física?";
        } else if (tipoAcao == 'recuperar') {
            var mensagem = "Tem certeza que deseja Recuperar essa Perda Física?";
        }

        this.mensagemConfirmacao(
            mensagem,
            'Faça sua escolha',
            this.executarAcao.bind(null, mensagemFalha, tipoAcao, id)
        )
    }

    confirmarAcaoReabertura(tipoAcao) {
        if(tipoAcao == 'salvarReabertura'){
            var mensagem = "Deseja realmente reabrir esta ocorrência?";
        }

        this.mensagemConfirmacao(
            mensagem,
            'Faça sua escolha',
            this.salvarMotivoReabertura
        )
    }
    
    executarAcao(mensagemFalha, tipoAcao, id) {
        this.http().post(`${this.serverUrl}/perda-fisica/alterar-status-perda-fisica`, { id: id, tipo: tipoAcao })
            .then(resp => {
                if (resp.data != null) {
                    Messages.success(resp.data.msg)
                    this.$swal.fire(resp.data.msg, '', 'success')
                    this.filter(this.page)
                } else {
                    Messages.success(mensagemFalha)
                }
            })
            .catch(() => { })
    }

    mensagemConfirmacao(titulo, mensagemPosConfirmacao, callback) {
        this.$swal({
            title: titulo,
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: false,
            cancelButtonText: `Cancelar`,
            confirmButtonText: `Ok`,
            icon: 'warning'
        }).then((result) => {
            if (result.isConfirmed) {
                callback()
            } else if (result.isDismissed) {
                this.$swal.fire('Operação cancelada', '', 'info')
            }
        })
    }

    abrirHistorico(idPerdaFisica) {
        this.listaStatusPerdaFisica = [];
        return this.http().get(`${this.serverUrl}/perda-fisica/get-historico-status-perda-fisica/${idPerdaFisica}`).then(resp => {
            this.listaStatusPerdaFisica = resp.data.statusArray;
            this.inicializarObjetos = resp.data.inicializarObjetos;
        });
    }

    abrirModalReabertura(idPerdaFisica) {

        this.idPerdaFisicaReabertura = idPerdaFisica;

        // Obtenha a data e hora atuais
        const agora = new Date().toLocaleString('sv', { timeZone: 'America/Sao_Paulo' }).slice(0, 16);
        this.dataHoraReabertura = agora;
    }

    validarMotivoReabertura() {
        if(!this.motivoReabertura) {
            Messages.error('É obrigatório informar um motivo de reabertura.');
            $("#motivoReabertura").trigger('focus');
            return;
        }

        if(this.motivoReabertura.length < 30) {
            Messages.error('É necessário informar pelo menos 30 caracteres.');
            $("#motivoReabertura").trigger('focus');
            return;
        }

        return true;
    }

    salvarMotivoReabertura() {
        if(!this.validarMotivoReabertura()){
            return;
        }

        this.http().post(`${this.serverUrl}/perda-fisica/salvar-motivo-reabertura`, {
            dataHoraReabertura: this.dataHoraReabertura,
            motivoReabertura: '- Reabertura de Processo -\n' + this.motivoReabertura,
            idPerdaFisica: this.idPerdaFisicaReabertura
        }).then(resp => {
            if (resp.data == null) {
                Messages.success('Reabertura Realiza com Sucesso!')
                this.motivoReabertura = null;
                $('#reabrirOcorrencia').modal('hide');
                this.filter(this.page)
            } else {
                Messages.error('Os dados não foram salvos!')
                this.$swal.fire('Erro!', '', 'error')
            }
        }).catch(() => {});
    }

    limparMotivoReabertura() {
        this.motivoReabertura = '';
    }

    gerarRelatorioPdf(idPerdaFisica) {
        this.http().post(`${this.serverUrl}/perda-fisica/gerar-relatorio-pdf`, {idPerdaFisicaRelatario: idPerdaFisica}).then(resp => {
            let byteCharacters = atob(resp.data);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let file = new Blob([byteArray], { type: 'application/pdf' });
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        })
    }

    filteredData() {
     
        return _.orderBy(this.perdasFisicas, item => item.lastStatusPerdasFisicas.dataHoraInfo, 'desc');
    }
}

export default PerdaFisicaListing