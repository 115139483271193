





































































































import SalaVacinaListing from "./SalaVacinaListing.script";
import hmr from "vue2-hmr/dist";

export default hmr(SalaVacinaListing, module);
