






































import LaboratorioForm from "./LaboratorioForm.script";
import hmr from "vue2-hmr/dist";

export default hmr(LaboratorioForm, module);
