import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Menu from "./components/Menu.vue";
import Topbar from "./components/Topbar.vue";
import Footer from "./components/Footer.vue";
import HttpMixin from 'vue2-http-mixin';
import PathMixin from 'vue2-path-mixin';
import ProgressBarInterceptor from './helpers/progressBarInterceptor';
import Loading from 'vue2-loading'

interface App extends HttpMixin, PathMixin {}

@Component({
    components: {Menu, Topbar, Footer},
    mixins: [HttpMixin, PathMixin],
})
class App extends Vue implements App {

    name= "Index"

    context = "/" + location.pathname.split("/")[1]
    public auth = false
    public login = null
    public senha = null

    mounted(){
        window.addEventListener('localstorage-changed', (event: any) => {
            this.auth = event.detail.storage;
        });
    }

    created(){
        localStorage.getItem('auth') != null ? localStorage.getItem('auth') : localStorage.setItem('auth', 'false')
        this.auth = String(localStorage.getItem('auth')) == 'true' ? true : false

        if (!ProgressBarInterceptor.loading) {
            ProgressBarInterceptor.loading = Loading.getInstance('#sesProgressBar')
        }
    }

    focusSenha(){
        $('#senha').focus()
    }

    logar() {
        this.http().post(this.serverUrl + "/auth/login", {
            login: this.login,
            password: this.senha,
        })
        .then((response) => {
            if (response.status === 200 && response.data.login != null) {
                localStorage.setItem('auth', 'true')
                
                window.dispatchEvent(new CustomEvent('localstorage-changed', {
                    detail: {
                        storage: localStorage.getItem('auth')
                    }
                }))

                this.$router.push({
                    path: "/",
                });

                localStorage.setItem('idUsuario', response.data.id)
                localStorage.setItem('tipoUsuario', response.data.idTipoUsuario)
                localStorage.setItem('idMunicipio', response.data.chvmncMunicipio)
                localStorage.setItem('idUnidade', response.data.idUnidade)
                localStorage.setItem('idRegional', response.data.idRegionalSaude)
                
                window.dispatchEvent(new CustomEvent('localstorage-tipoUsuario', {
                    detail: {
                        tipoUsuario: localStorage.getItem('tipoUsuario')
                    }
                }))

                this.login = null
                this.senha = null
                return;
            }
            
        }).catch((error) => {
            this.mensagemErro("Login e/ou senha inválido(s)");
        })
    }

    resetarSenhaLogin() {
        this.http().post(`${this.serverUrl}/auth/resetar-senha-login`, {login: this.login})
            .then(resp => {
                if (resp.data == true) {
                    this.$swal.fire('Senha Alterada com Sucesso, verifique seu e-mail!', '', 'success')
                } else if (resp.data == 'SEM_EMAIL') {
                    this.$swal.fire('Usuário sem e-mail, verificar com o Administrador para resetar senha!', '', 'error')
                } else {
                    this.$swal.fire('Login Invalido!', '', 'error')
                }
            })
            .catch(() => {})
    }

    mensagemErro(texto) {
        this.$swal.fire(texto, "", "error").then((result) => {
          if (result.isConfirmed) {
            $("#login").select().focus()
          }
        })
    }
}

export default App
