









































































import UnidadeForm from "./UnidadeForm.script";
import hmr from "vue2-hmr/dist";

export default hmr(UnidadeForm, module);
