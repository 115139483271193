import Imunobiologico from "./imunobiologico"
import Laboratorio from "./laboratorio"

class NumeroLote {
    id : null
    idImunobiologico: null
    idLaboratorioProdutor: null
    imunobiologico: Imunobiologico = new Imunobiologico()
    laboratorioProdutor: Laboratorio = new Laboratorio()
    numeroLote : null
    excluido : null
}

export default NumeroLote