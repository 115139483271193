

















































import NumeroLoteDiluenteForm from "./NumeroLoteDiluenteForm.script";
import hmr from "vue2-hmr/dist";

export default hmr(NumeroLoteDiluenteForm, module);
