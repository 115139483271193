import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import store from '../../store'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import Messages from 'vue2-messages'
import { VueSelect } from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Pagination from '../../components/Pagination.vue'
import fmt from '../../helpers/fmt'
import Unidade from '../../models/unidade'

interface UnidadeListing extends PageMixin {}

@Component({
    components: {'v-select': VueSelect, 'paginacao': Pagination},
    mixins: [PageMixin],
    store
})
class UnidadeListing extends Vue implements UnidadeListing {
    fmt = fmt

    name = 'UnidadeListing'

    tipoUsuario = {};
    regionais = [];
    municipios = [];
    unidadesList = [];
    unidadesFiltro = [];

    model = new Unidade();

    filtro = {
        idRegional: null,
        idMunicipio: null,
        idUnidade: null,
        excluido : false
    }
    
    limit = 10
    limits = [10, 20, 50, 100]
    page = 1
    totalPages = 0
    pagination = {totalCount: 1}

    pageChanged (page) {            
        this.page = page
        this.filter(page);
    }

    limitSelect(){
        this.page = 1
        this.buscarUnidades();
    }

    created() {
        this.unidadesList = [];

        this.inicializarObjetos();
        this.setarDadosUsuarioLogado();
        this.limpar();
    }

    mounted() {
        
    }

    inicializarObjetos(){
        this.http().get(`${this.serverUrl}/unidade/inicializar-objetos/`).then(resp => {
            this.regionais = resp.data.regionais;
        });
    }

    setarDadosUsuarioLogado(){
        this.tipoUsuario = localStorage.getItem('tipoUsuario')
        if(this.tipoUsuario == "2"){
            this.filtro.idRegional = localStorage.getItem('idRegional')
        }
        if(this.tipoUsuario == "3"){
            this.filtro.idMunicipio = localStorage.getItem('idMunicipio')
            this.filtrarUnidadePorMunicipio();
        }
        if(this.tipoUsuario == "4"){
            this.filtro.idUnidade = localStorage.getItem('idUnidade')
        }
    }

    filtrarMunicipiosPorRegional(idRegional){
        if(idRegional == null){
            this.unidadesFiltro = [];
        }
        let arrMunicipios = this.municipios.filter(municipio => {
            return municipio?.regional.id == idRegional;
        });
        return arrMunicipios;
    }

    filtrarUnidadePorMunicipio(){
        this.unidadesFiltro = [];
        Vue.set(this.filtro, 'idUnidade', null);
        if(this.filtro.idMunicipio != null){
            return this.http().get(`${this.serverUrl}/unidade/listar-unidades/${this.filtro.idMunicipio}`).then(resp => {
                this.unidadesFiltro = resp.data.unidades;
            });
        }
    }

    changeRegional(){
        this.filtro.idMunicipio = null;
        this.unidadesFiltro = [];
    }

    inicializarDadosFiltros(){
        this.http().get(`${this.serverUrl}/unidade/dados-filtros`)
        .then(res => {
            this.regionais = res.data.regionais;
            this.municipios = res.data.municipios;
        })
    }

    filter(page) {
        this.http().get(`${this.serverUrl}/unidade/filter?filtros=${JSON.stringify(this.filtro)}&page=${page}&per-page=${this.limit}`)
            .then(res => {
                this.unidadesList = res.data.unidades
                this.pagination = res.data?.pagination
                this.totalPages = Math.ceil(this.pagination?.totalCount / this.limit)
                $('[data-toggle="tooltip"]').tooltip()
            })
    }

    buscarUnidades() {
        return this.http().get(`${this.serverUrl}/unidade/${this.page}/${this.limit}`);
    }

    limpar(){
        this.filtro = {
            idRegional: null,
            idMunicipio: null,
            idUnidade: null,
            excluido : false
        };
        this.setarDadosUsuarioLogado();
        this.filter(1);
    }

    confirmarAcao(id, tipoAcao) {
        var mensagemFalha = "Ocorreu uma falha ao executar a ação";
        if(tipoAcao == 'excluir'){
            var mensagem = "Tem certeza que deseja Excluir essa Unidade?";
            var mensagemSucesso = "Exclusão realizada com sucesso";
        }else if(tipoAcao == 'recuperar'){
            var mensagem = "Tem certeza que deseja Recuperar essa Unidade?";
            var mensagemSucesso = "Recuperação realizada com sucesso";
        }

        this.mensagemConfirmacao(
            mensagem, 
            'Faça sua escolha', 
            mensagemSucesso, 
            this.executarAcao.bind(null, mensagemSucesso, mensagemFalha, tipoAcao, id)
          )
    }
    
    executarAcao(mensagemSucesso, mensagemFalha, tipoAcao, id) {
        this.http().post(`${this.serverUrl}/unidade/excluir-recuperar`, {id: id, tipo: tipoAcao})
            .then(resp => {
                if (resp.data != null) {
                    Messages.success(mensagemSucesso)
                    this.filter(this.page)
                    this.$swal.fire(resp.data.msg, '', 'success')
                } else {
                    Messages.success(mensagemFalha)
                }
            })
            .catch(() => {})
    }

    mensagemConfirmacao(titulo, corpoMensagem, mensagemPosConfirmacao, callback){
        this.$swal({
          title: titulo,
          html: corpoMensagem,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Ok`,
          icon: 'warning'
        }).then((result) => {
          if (result.isConfirmed) {
            callback()
          } else if (result.isDismissed) {
            this.$swal.fire('Operação cancelada', '', 'info')
          }
        })
    }
}

export default UnidadeListing