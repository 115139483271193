















export default {

  name: "Imunobiologico",

  data() {
    return {
      
    };
  },

  methods: {

  },
};
