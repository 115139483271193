





























































































































































































































































import Playground2 from './Playground2.script'
import hmr from 'vue2-hmr/dist'

export default hmr(Playground2, module)
