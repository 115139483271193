import Unidade from "./unidade"
import Usuario from "./usuario"

class SalaVacina {
    id : null
    idUnidade : null
    unidade : Unidade = new Unidade()
    idUsuarioCadastrou : null
    usuarioCadastrou : Usuario = new Usuario()
    nomeSalaVacina : null
    cnesSalaVacina : null
    excluido : null
}

export default SalaVacina