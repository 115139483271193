import Diluente from "./diluente"
import Laboratorio from "./laboratorio"

class NumeroLoteDiluente {
    id : null
    idDiluente: null
    idLaboratorioProdutor: null
    diluente: Diluente = new Diluente()
    laboratorioProdutor: Laboratorio = new Laboratorio()
    numeroLote : null
    excluido : null
}

export default NumeroLoteDiluente