



































































































































































































































export default {
  name: "Topbar",
  data() {
    return {
      teste: "",
    };
  },
  methods: {
    toggleSidebar() {
      $("body").toggleClass("sidebar-toggled");
      $(".sidebar").toggleClass("toggled");
      $(`.dropdown-menu-right`).addClass("submenu-no-toggle");
      $(`.dropdown-menu-right`).removeClass("sub-submenu-right");
      $(`.dropdown-menu-right`).removeClass("submenu-right");

      if ($(".sidebar").hasClass("toggled")) {
        $(".sidebar .collapse").collapse("hide");
        $(`.dropdown-menu-right`).removeClass("submenu-no-toggle");
        
        var submenus = $(".dropdown-menu-right");
        for (var index in submenus) {
          if ($(`.dropdown-menu-right:eq(${index})`).parent().parent().parent().parent().hasClass("dropdown-menu-right")) {
            $(`.dropdown-menu-right:eq(${index})`).addClass("sub-submenu-right");
          } else {
            $(`.dropdown-menu-right:eq(${index})`).addClass("submenu-right");
          }
        }

      }
    },
  },
};
