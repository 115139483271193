








































import ImunobiologicoForm from "./ImunobiologicoForm.script";
import hmr from "vue2-hmr/dist";

export default hmr(ImunobiologicoForm, module);
