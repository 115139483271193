import Municipio from "./municipio"
import Unidade from "./unidade"

class Usuario {
    id: null
    nome: null
    cpf: null
    email: null
    telefone: null
    unidade: Unidade = new Unidade()
    idUnidade: null
    municipio: Municipio = new Municipio()
    chvmncMunicipio: null
    idTipoUsuario: null
    login: null
    senha: null
    senhaAtual: null
    senhaNova: null
    idRegionalSaude: null
    regional: null
}

export default Usuario