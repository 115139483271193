import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import store from '../store'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import { CombinedVueInstance } from 'vue/types/vue'
import Loading from 'vue2-helpers/dist/loading'
import { VueSelect } from 'vue-select'
import 'vue-select/dist/vue-select.css'

interface Playground2 extends PageMixin {}

@Component({
    components: {'v-select': VueSelect},
    mixins: [PageMixin],
    store,
})
class Playground2 extends Vue implements Playground2 {

    name = 'Playground2'

    model = {
        nome: '',
        cpf: '',
        funcionario: false,
        experiencia: null,

        cidade: {
            nome: '',
            estado: {
                nome: '',
                pais: {}
            }
        }
    }
    sexos = {
        'm': 'Masculino',
        'f': 'Feminino',
    }

    messages = {
        fotos: [],
        model: {cidade: null, sexo: null, funcionario: null},
    }

    experiencias: any[]

    messageCallback(messagePath: string, message, dataset) {

        const [nome, indice] = messagePath.split('.').pop().split(/[\[\]]/)

        const $input = $(`[name='${nome}[]']:eq(${indice}) + div input`)
        const $botao = $(`[name='${nome}[]']:eq(${indice}) + div .btn`)
        
        if (message) {
            $input.addClass('is-invalid')
            $botao.addClass('btn-danger')
        }
        else {
            $input.removeClass('is-invalid')
            $botao.removeClass('btn-danger')
        }
    }

    change() {
        console.log(new Date().toTimeString())
    }

    created() {
        this.experiencias = [ 
            { text: 'Júnior', value: 1 },
            { text: 'Pleno', value: 2 },
            { text: 'Sênior', value: 3 },
        ]
    }

    mounted() {
        // estilizar os botoes de upload
        const seletorUploads = '#playground2 input:file'
        this.styleUploadButtons(seletorUploads)

        $(seletorUploads).on('change', ($event) => {
            Vue.set(this.messages.fotos, +$event.target.getAttribute('indice'), '')
        })

        Vue.set(this.messages.fotos, 0, 'arquivo faltando!')
        this.messages.model.cidade = 'Campo Cidade está inválido'
        // this.texto = 'textao grande'

    }

    enviar() {
        var files = []
        $.each($("input[name='fotos[]']"), function(idx: number, field: any){
            files.push(field.value)
        })
        this.http().upload(`/envio`, '#playground2 input:file', {model: this.$data.model, files})
    }

    alertar() {
        window.alert('okoko')
    }

    teste() {
        this.model.cidade.nome = 'Goiânia'
        this.model.cidade.estado.nome = 'GO'

        console.log(this.$data.teste)

        // this.http().post(`/teste`, this.$data.teste)
    }

    teste2() {
        this.http().get('/teste2')
        .then(() => this.http().get('/teste2')
            .then(() => this.http().get('/teste2')
                .then(() => this.http().get('/teste2'))
            )
        )
    }

    selecionarArquivo(idArquivo) {
        const fileinput = ($(`#${idArquivo}`).get(0) as HTMLInputElement)
        console.log(fileinput.files)
        console.log('ok')
    }

    mensagem(){
        this.mensagemConfirmacao(
          'Tem certeza que deseja realizar essa ação?', 
          'Faça sua escolha', 
          'Ação realizada com sucesso', 
          this.alertar
        )
      }

    mensagemConfirmacao(titulo, corpoMensagem, mensagemPosConfirmacao, callback){
        this.$swal({
          title: titulo,
          html: corpoMensagem,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Ok`,
          icon: 'warning'
        }).then((result) => {
          if (result.isConfirmed) {
            callback()
            this.$swal.fire(mensagemPosConfirmacao, '', 'success')
          } else if (result.isDismissed) {
            this.$swal.fire('Operação cancelada', '', 'info')
          }
        })
    }


}

export default Playground2
