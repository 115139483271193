import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import store from '../../store'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import { VueSelect } from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Messages from 'vue2-messages'
import fmt from '../../helpers/fmt'
import NumeroLote from '../../models/numeroLote'
import SalaVacina from '../../models/salaVacina'

interface SalaVacinaForm extends PageMixin {}

@Component({
    components: {'v-select': VueSelect},
    mixins: [PageMixin],
    store,
})
class SalaVacinaForm extends Vue implements SalaVacinaForm {
    fmt = fmt

    name = 'SalaVacinaForm';

    model = new SalaVacina();

    unidades = [];
       
    idSalaVacina = null;
    tipoUsuario = {}
    
    messages = {
        model: this.model,
    };

    change() {

    }

    computed(){

    }
    
    created() {
        this.idSalaVacina = this.$route.params.id == "new" ? null : this.$route.params.id
        if(this.idSalaVacina == null){
            this.model = new SalaVacina();
        }
        this.inicializarObjetos();
        this.getSalaDeVacina();
    }

    mounted() {

    }

    getSalaDeVacina(){
        if(this.idSalaVacina != null){
            this.http().get(`${this.serverUrl}/sala-vacina/get-sala-de-vacina/${this.idSalaVacina}`)
            .then(res => {
                if(res.data.erro){
                    this.$swal.fire({title: res.data.msg+' - Redirecionando para a listagem', text: '', icon: 'error', timer: 2700});
                    setTimeout(() => {
                        this.$router.push('/sala-vacina');
                    } , 3000);
                    return;
                }
                this.model = res.data;
            });
        }
    }

    inicializarObjetos(){
        this.http().get(`${this.serverUrl}/unidade/listar-unidades`).then(resp => {
            this.unidades = resp.data.unidades;
        });
    }

    salvar() {
        if(this.model.nomeSalaVacina == null || this.model.nomeSalaVacina == ''){
            Messages.error('Por favor, preencha o campo "Nome da Sala de Vacina".');
            $("#nomeSalaVacina").trigger('focus');
            return;
        }
        if(this.model.cnesSalaVacina == null || this.model.cnesSalaVacina == ''){
            Messages.error('Por favor, preencha o campo "CNES da Sala de Vacina".');
            $("#cnesSalaVacina").trigger('focus');
            return;
        }
        if(this.model.idUnidade == null){
            Messages.error('Por favor, selecione a que Unidade esta sala pertence.');
            $("#idUnidade").trigger('focus');
            return;
        }
        this.http().post(`${this.serverUrl}/sala-vacina/salvar`, {
            model: this.model
         })
        .then(resp => {
            if(resp.data.error){
                Messages.error(resp.data.error)
                this.$swal.fire('Erro!', '', 'error')
                return;
            }
            if (resp.data != null) {
                if(this.$route.params.id == "new"){
                    var msg = "Dados Salvo com Sucesso!";
                    this.limpar();
                }else{
                    var msg = "Dados Editados com Sucesso!";
                }
                Messages.success(msg)
                this.$swal.fire('Sucesso!', '', 'success')
                this.$swal.fire({title: 'Sucesso!', text: '', icon: 'success', timer: 1250});
            } else {
                Messages.success('Os dados não foram salvos!')
                this.$swal.fire('Erro!', '', 'error')
            }
        }).catch(() => {});
    }

    limpar(){
        this.model = new SalaVacina();
    }

}

export default SalaVacinaForm