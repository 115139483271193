



































































































import NumeroLoteListing from "./NumeroLoteListing.script";
import hmr from "vue2-hmr/dist";

export default hmr(NumeroLoteListing, module);
