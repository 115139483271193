import moment, { HTML5_FMT } from 'moment'

moment().locale('pt-br')

function dateTime(data, pattern = 'DD/MM/YYYY H:mm:ss') {
    return moment(data).format(pattern)
}

function date(data, pattern = 'DD/MM/YYYY') {
    return moment(data).format(pattern)
}

const fmt = {
    dateTime,
    date,
}

export default fmt