













export default {

  name: "PDFOcorrenciaImunobiologico",

  data() {
    return {
      
    };
  },

  methods: {

  },
};
