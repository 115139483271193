











    export default {
        components: {
            
        },

        name: "pagination",
        props: {
            pages: Number,
            showPrevNext: Boolean,
            offset: Number,
            callback: Function
        },

        data(){
            return{
                offsetData: this.offset,
            }
        },

        computed: {
            formatPages(){
                let formatPages = []
                if((this.offset - 5) > 0 && (this.offset + 5) > this.pages){
                    for(var i = this.pages; i > (this.pages - 10); i--){
                        formatPages.unshift(i)
                    }
                }else if((this.offset - 5) > 0){
                    for(var j = 1; j <= 10; j++){
                        if(( (this.offset - 5) + j) <= this.pages){
                            formatPages.push( ( (this.offset - 5) + j) )
                        }
                    }
                }else{
                    for(var k = 1; k <= 10; k++){
                        if(k <= this.pages || (k == 1 && this.pages == 0))
                            formatPages.push(k)
                    }
                }

                return formatPages
            }
        },

        methods: {
            pageSelect(page){
                this.offsetData = page
                this.offset = this.offsetData
                this.callback(page)
            },
            prevPage(){
                if(this.offsetData - 1 >= 1){
                    this.offsetData--
                    this.offset = this.offsetData
                    this.callback(this.offsetData)
                }
            },
            nextPage(){
                if(this.offsetData + 1 <= this.pages){
                    this.offsetData++
                    this.offset = this.offsetData
                    this.callback(this.offsetData)
                }
            },
        }
    };
