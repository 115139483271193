import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import store from '../../store'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import { VueSelect } from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Messages from 'vue2-messages'
import Usuario from '../../models/usuario'

interface UsuarioForm extends PageMixin {}

@Component({
    components: {'v-select': VueSelect},
    mixins: [PageMixin],
    store,
})
class UsuarioForm extends Vue implements UsuarioForm {

    name = 'UsuarioForm'

    model = {
        id: null,
        nome: '',
        cpf: '',
        email: '',
        telefone: '',
        unidade: {id: null, municipio: {chvmnc: null}},
        idUnidade: null,
        municipio: {chvmnc: null, nome: null},
        chvmncMunicipio: null,
        idTipoUsuario: null,
        login: '',
        senha: '',
        senhaAtual: '',
        senhaNova: '',
        idRegionalSaude: null,
        regional: null
    }

    tiposUsuarios = {}
    tiposRegionais = {};
    

    usuarios = []
    unidades = []
    municipios = []
    tipoUsuario = {}
    
    idUsuario = null
    
    messages = {
        model: {nome: null, cpf: null, email: null, 
            telefone: null, unidade: null, senha: null, senhaAtual: '', senhaNova: ''},
    }

    change() {
        console.log(new Date().toTimeString())
    }
    
    salvar() {
        let validarCampoLogin = false
        if(!this.model.login){
            this.$swal.fire('É obrigatório informar um login!', '', 'error')
            return
        }else{
            validarCampoLogin = this.validaCampoLogin(this.model.login)
        }

        if (validarCampoLogin) {
            if (this.model.idTipoUsuario == '' || this.model.idTipoUsuario == null) {
                this.$swal.fire('Escolha o tipo deste usuário!', '', 'error')
                return
            }

            if (!this.model.email) {
                this.$swal.fire('É obrigatório informar o e-mail!', '', 'error')
                return
            }

            if (!this.model.telefone) {
                this.$swal.fire('É obrigatório informar o telefone!', '', 'error')
                return
            }

            if(this.model.idTipoUsuario == 1) {
                this.model.chvmncMunicipio = null
                this.model.idUnidade = null
                this.model.regional = null;
            }else if (this.model.idTipoUsuario == 2) {
                this.model.chvmncMunicipio = null
                this.model.idUnidade = null
                if (this.model.idRegionalSaude == '' || this.model.idRegionalSaude == null) {
                    this.$swal.fire('Selecione uma Regional!', '', 'error')
                    return
                }
            }else if (this.model.idTipoUsuario == 3) {
                this.model.idUnidade = null
                this.model.regional = null;
                if (this.model.chvmncMunicipio == '' || this.model.chvmncMunicipio == null) {
                    this.$swal.fire('Selecione um Município!', '', 'error')
                    return
                }
            }else if (this.model.idTipoUsuario == 4) {
                this.model.regional = null;
                if ((this.model.chvmncMunicipio == '' || this.model.chvmncMunicipio == null) || (this.model.idUnidade == '' || this.model.idUnidade == null)) {
                    this.$swal.fire('Selecione um Município e Unidade!', '', 'error')
                    return
                }
            }

            if (!this.idUsuario && (this.model.senha == '' || this.model.senha == null)) {
                this.$swal.fire('A Senha é Obrigatória!', '', 'error')
                return
            }

            this.http().post(`${this.serverUrl}/usuario/salvar`, {model: this.model})
                .then(resp => {
                    if(resp.data.error){
                        Messages.error(resp.data.error)
                        this.$swal.fire('Erro!', '', 'error')
                        return;
                    }
                    if (resp.data != null) {
                        if(this.$route.params.id == "new"){
                            this.limpar();
                            var msg = "Usuário Cadastrado";
                        }else{
                            var msg = "Usuário Editado";
                        }
                        Messages.success(msg)
                        this.$swal.fire('Sucesso!', '', 'success')
                    } else {
                        Messages.success('Usuário não Cadastrado')
                        this.$swal.fire('Erro!', '', 'error')
                    }
                }).catch(() => {});
        }
    }

    alterarSenha() {
        if (this.model.senhaAtual != "" && this.model.senhaNova != "") {
            var senhaAlterada = false

            this.http().post(`${this.serverUrl}/usuario/alterar-senha`, {model: this.model})
                .then(resp => {
                    if (resp.data != null) {
                        senhaAlterada = true
                        if (senhaAlterada) {
                            Messages.success('Senha Alterada!')
                        }
                    } else {
                        Messages.error('Ocorreu um erro ao alterar a senha. A senha atual pode estar incorreta')
                    }
                })
                .catch(() => {})
        }
    }

    limpar(){
        this.model = new Usuario();
    }

    created() {
        this.idUsuario = this.$route.params.id == "new" ? null : this.$route.params.id
        this.tipoUsuario = localStorage.getItem('tipoUsuario')

        this.buscarTiposUsuario().then(res => {
            this.tiposUsuarios = res.data.tiposUsuario;
        });

        if (this.idUsuario == null) {
            this.limpar()
        }

        if(this.idUsuario != null){
            this.http().get(`${this.serverUrl}/usuario/get-usuario/${this.idUsuario}`)
            .then(res => {
                this.model = res.data.usuario;

                if(this.model?.idTipoUsuario == 2){
                    this.buscarRegionais().then(res => {
                        this.tiposRegionais = res.data.regionais;
                    });
                }
                if([3, 4].includes(this.model?.idTipoUsuario)){
                    //VERIFICA SE TEM UNIDADE E MUNICIPIO OU SE TEM SOMENTE MUNICIPIO
                    if (res.data.usuario.unidade != null) {
                        Vue.set(this.model, 'municipio', {id : res.data.usuario?.unidade?.municipio?.chvmnc, nome: res.data.usuario?.unidade?.municipio?.nome});
                    } else {
                        if(res.data.usuario.municipio){
                            Vue.set(this.model, 'municipio', {id : res.data.usuario?.municipio?.chvmnc, nome: res.data.usuario.municipio.nome});
                        }
                    }
                    this.buscarMunicipios().then(res => {
                        this.municipios = res.data.municipios;
                    });
                    if(this.model.chvmncMunicipio && this.model?.idTipoUsuario == 4){
                        this.buscarUnidades(this.model.chvmncMunicipio).then(res => Vue.set(this.unidades,'',res.data.unidades));
                    }
                }

            })
        }
    }

    mounted() {

    }

    buscarTiposUsuario(){
        return this.http().get(`${this.serverUrl}/tipo-usuario/listar-tipo-usuario/`);
    }

    buscarUnidades(id?:number){
        return this.http().get(`${this.serverUrl}/unidade/listar-unidades/${id}`);
    }

    buscarMunicipios(){
        return this.http().get(`${this.serverUrl}/municipio/listar-municipios`);
    }

    buscarRegionais(){
        return this.http().get(`${this.serverUrl}/municipio/listar-regionais`);
    }

    changeTipoUsuario(){
        Vue.set(this.model,'municipio', null)
        Vue.set(this.model,'unidade', null)
        this.model.chvmncMunicipio = null;
        this.model.idUnidade = null;
        this.model.idRegionalSaude = null
        this.unidades = [];

        if(this.model.idTipoUsuario == '2'){
            this.buscarRegionais().then(res => {
                this.tiposRegionais = res.data.regionais;
            });
        }else if([3, 4].includes(this.model.idTipoUsuario)){
            this.buscarMunicipios().then(res => {
                this.municipios = res.data.municipios;
            });
        }

    }

    changeUnidade(){
        if (this.model.unidade == null) {
            this.model.idUnidade = null   
        } else {
            this.model.idUnidade = this.model.unidade.id
        }
    }

    changeMunicipio(){
        this.model.chvmncMunicipio = this.model.municipio?.chvmnc;
        if(this.model.municipio && this.model.idTipoUsuario == '4'){
            this.model.chvmncMunicipio = this.model.municipio.chvmnc;
            this.buscarUnidades(this.model.chvmncMunicipio).then(res => {
                Vue.set(this.unidades,'',res.data.unidades)
            });
        }else{
            this.unidades = [];
            this.model.idUnidade = null;
            Vue.set(this.model,'unidade', null)
        }
        Vue.set(this.model,'unidade', null)
    }

    validaCampoLogin(login) {
        var msg = ""
        if ( login.search( /\s/g ) != -1 )
        {
            msg+= "Não é permitido espaços em branco no Login\n"
            login = login.replace( /\s/g , "" )
        }	
        // if ( login.search( /[^a-z0-9]/i ) != -1 )
        // {
        //     msg += "Não é permitido caracteres especiais em Login"
        //     login = login.replace( /[^a-z0-9]/gi , "" )
        // }
        if ( msg )
        {
            Messages.error(msg)
            login.login.value = login
            return false
        }
        return true	
    }
}

export default UsuarioForm