import Regional from "./regional"

class Municipio {
    chvmnc : null
    codigoIbge : null
    nome : null
    unidadeFederacao : null
    exclusao : null
    idAganp : null
    numeroHabitantes : null
    regional : Regional = new Regional()
}

export default Municipio