import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import { VueSelect } from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueJsonToCsv from 'vue-json-to-csv';
import downloadexcel from "vue-json-excel"

interface RelatorioPerdaFisicaForm extends PageMixin {}

@Component({
    components: {'v-select': VueSelect, 'vue-json-to-csv': VueJsonToCsv, downloadexcel},
    mixins: [PageMixin],
})
class RelatorioPerdaFisicaForm extends Vue implements RelatorioPerdaFisicaForm {
    json_fields = {
        "ID": "id",
        "Data": "data",
        "Tipo": "tipo",
        "Local": "local",
        "Sala": "sala",
        "Unidade": "unidade",
        "CNES": "cnes",
        "Município": "municipio",
        "Regional": "regional",
        "Ocorreu Em": "ocorreuEm",
        "Visualizado Congelamento": "visualizadoCongelamento",
        "Motivo": "motivo",
        "Alteracao Temperatura": "alteracaoTemp",
        "Medida Adotada": "medidaAdotada",
        "Resposta por Imunobiológico"  : "respostaPorImunobiologico",
        "Laboratorio": "laboratorio",
        "Lote": "lote",
        "Local de conservação": "localConservacao",
        "Qtd Frasco": "qtdFrasco",
        "Qtd Dose": "qtdDose",
        "Imunobiológico"  : "imunobiologico",
        "Já houve excursão anterior"  : "excursaoAnterior",
        "Status"  : "status",
    }
    json_meta = [
        [
            {
                key: "charset",
                value: "utf-8",
            },
        ],
    ]
    
    dados = [];

    name = 'RelatorioPerdaFisicaForm'

    filtro = {
        unidade: '',
        cnes: '',
        municipio: '',
        uf: '',
        regional: '',
        dataInicio: '',
        dataFim: '',
        status: '',
        local: '',
        localConservacao: '',
    }

    messages = {
        filtro: {
            unidade: '',
            cnes: '',
            municipio: '',
            uf: '',
            regional: '',
            dataInicio: '',
            dataFim: '',
            status: '',
            local: '',
            localConservacao: '',
        }
    }

    tipoUsuario = {};
    ufs = [];
    regionais = [];
    municipios = [];
    unidadesList = [];
    unidadesFiltro = [];
    statusPerdaFisica = [];
    tiposLocalOcorrencia = [];
    tiposLocalConservacao = [];
    localConservacaoSelected: []

    created() {
        this.inicializarObjetos()
    }

    mounted() {

    }

    data () {
        return {
          locaisConservacao: [
            'Caixa Térmica', 'Câmara Refrigerada', 'Geladeira Doméstica', 'Outros',
          ],
        }
    }

    limpar(){
        Vue.set(this.filtro, 'unidade', '');
        Vue.set(this.filtro, 'cnes', '');
        Vue.set(this.filtro, 'municipio', '');
        Vue.set(this.filtro, 'uf', '');
        Vue.set(this.filtro, 'regional', '');
        Vue.set(this.filtro, 'dataInicio', '');
        Vue.set(this.filtro, 'dataFim', '');
        Vue.set(this.filtro, 'status', '');
        Vue.set(this.filtro, 'local', '');
        Vue.set(this.filtro, 'localConservacao', '');
    }

    emitirRelatorio(){
        this.http().post(`${this.serverUrl}/perda-fisica/relatorio-ocorrencias-imunobiologico`, { ...this.filtro }).then(res => {
            let byteCharacters = atob(res.data);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let file = new Blob([byteArray], { type: 'application/pdf' });
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });
    }

    emitirRelatorioExcel(){
        this.http().post(`${this.serverUrl}/perda-fisica/relatorio-ocorrencias-imunobiologico-dados`, { ...this.filtro }).then(res => {
            this.dados = res.data;
            setTimeout(function(){$("#downloadExcel").trigger("click")}, 500);
        });
    }
    

    inicializarObjetos(){
        this.http().get(`${this.serverUrl}/unidade/inicializar-objetos/`).then(resp => {
            this.regionais = resp.data.regionais;
        });
        this.http().get(`${this.serverUrl}/perda-fisica/inicializar-relatorio/`).then(resp => {
            this.ufs = resp.data.ufs;
            this.statusPerdaFisica = resp.data.statusPerdaFisica;
            this.tiposLocalOcorrencia = resp.data.tiposLocalOcorrencia;
            this.tiposLocalConservacao = resp.data.tiposLocalConservacao;
        });  
    }
    setarDadosUsuarioLogado(){
        this.tipoUsuario = localStorage.getItem('tipoUsuario')
        if(this.tipoUsuario == "2"){
            this.filtro.regional = localStorage.getItem('idRegional')
        }
        if(this.tipoUsuario == "3"){
            this.filtro.municipio = localStorage.getItem('idMunicipio')
            this.filtrarUnidadePorMunicipio();
        }
        if(this.tipoUsuario == "4"){
            this.filtro.unidade = localStorage.getItem('idUnidade')
        }
    }
    filtrarMunicipiosPorRegional(idRegional){
        if(idRegional == null){
            this.unidadesFiltro = [];
        }
        let arrMunicipios = this.municipios.filter(municipio => {
            return municipio?.regional.id == idRegional;
        });
        return arrMunicipios;
    }
    filtrarUnidadePorMunicipio(){
        this.unidadesFiltro = [];
        Vue.set(this.filtro, 'unidade', '');
        if(this.filtro.municipio != ''){
            return this.http().get(`${this.serverUrl}/unidade/listar-unidades/${this.filtro.municipio}`).then(resp => {
                this.unidadesFiltro = resp.data.unidades;
            });
        }
    }

    changeRegional(){
        // this.filtro.municipio = '';
        this.unidadesFiltro = [];
    }
}

export default RelatorioPerdaFisicaForm