import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        mailing: {
            cancelado: false
        },
        auth: true
    },
    actions: {
        cancel(){
            this.state.mailing.cancelado = true
        }
    }
})
