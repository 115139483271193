import Vue from 'vue'
import VueRouter from 'vue-router'
import $ from 'jquery'
import Erro404 from '../views/Erro404.vue'

const Home = () => import('../views/Home.vue')
const Playground = () => import('../views/Playground2.vue')

const Usuario = () => import('../views/Usuario/Usuario.vue')
const UsuarioListing = () => import('../views/Usuario/UsuarioListing.vue')
const UsuarioForm = () => import('../views/Usuario/UsuarioForm.vue')

const PerdaFisica = () => import('../views/PerdaFisica/PerdaFisica.vue')
const PerdaFisicaListing = () => import('../views/PerdaFisica/PerdaFisicaListing.vue')
const PerdaFisicaForm = () => import('../views/PerdaFisica/PerdaFisicaForm.vue')

const Unidade = () => import('../views/Unidade/Unidade.vue')
const UnidadeListing = () => import('../views/Unidade/UnidadeListing.vue')
const UnidadeForm = () => import('../views/Unidade/UnidadeForm.vue')

const Imunobiologico = () => import('../views/Imunobiologico/Imunobiologico.vue')
const ImunobiologicoListing = () => import('../views/Imunobiologico/ImunobiologicoListing.vue')
const ImunobiologicoForm = () => import('../views/Imunobiologico/ImunobiologicoForm.vue')

const Diluente = () => import('../views/Diluente/Diluente.vue')
const DiluenteListing = () => import('../views/Diluente/DiluenteListing.vue')
const DiluenteForm = () => import('../views/Diluente/DiluenteForm.vue')

const Laboratorio = () => import('../views/Laboratorio/Laboratorio.vue')
const LaboratorioListing = () => import('../views/Laboratorio/LaboratorioListing.vue')
const LaboratorioForm = () => import('../views/Laboratorio/LaboratorioForm.vue')

const NumeroLote = () => import('../views/NumeroLote/NumeroLote.vue')
const NumeroLoteListing = () => import('../views/NumeroLote/NumeroLoteListing.vue')
const NumeroLoteForm = () => import('../views/NumeroLote/NumeroLoteForm.vue')

const NumeroLoteDiluente = () => import('../views/NumeroLoteDiluente/NumeroLoteDiluente.vue')
const NumeroLoteDiluenteListing = () => import('../views/NumeroLoteDiluente/NumeroLoteDiluenteListing.vue')
const NumeroLoteDiluenteForm = () => import('../views/NumeroLoteDiluente/NumeroLoteDiluenteForm.vue')

const SalaVacina = () => import('../views/SalaVacina/SalaVacina.vue')
const SalaVacinaListing = () => import('../views/SalaVacina/SalaVacinaListing.vue')
const SalaVacinaForm = () => import('../views/SalaVacina/SalaVacinaForm.vue')

const RelatorioPerdaFisica = () => import('../views/RelatorioPerdaFisica/RelatorioPerdaFisica.vue')
const RelatorioPerdaFisicaForm = () => import('../views/RelatorioPerdaFisica/RelatorioPerdaFisicaForm.vue')

const PDFOcorrenciaImunobiologico = () => import('../views/PDFOcorrenciaImunobiologico/PDFOcorrenciaImunobiologico.vue')
const PDFOcorrenciaImunobiologicoForm = () => import('../views/PDFOcorrenciaImunobiologico/PDFOcorrenciaImunobiologicoForm.vue')


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        props: true,
    },
    {
        path: '/playground',
        name: 'playground',
        component: Playground,
        props: true,
    },
    {
        path: '/usuario',
        name: 'usuario',
        component: Usuario,
        props: true,
        children: [
            {
                path: '/',
                name: 'usuarioListing',
                component: UsuarioListing,
                props: true,
            },
            {
                path: ':id',
                name: 'usuarioForm',
                component: UsuarioForm,
                props: true,
            },
        ]
    },
    {
        path: '/perda-fisica',
        name: 'perda-fisica',
        component: PerdaFisica,
        props: true,
        children: [
            {
                path: '/',
                name: 'perdaFisicaListing',
                component: PerdaFisicaListing,
                props: true,
            },
            {
                path: ':id',
                name: 'perdaFisicaForm',
                component: PerdaFisicaForm,
                props: true,
            },
        ]
    },
    {
        path: '/unidade',
        name: 'unidade',
        component: Unidade,
        props: true,
        children: [
            {
                path: '/',
                name: 'unidadeListing',
                component: UnidadeListing,
                props: true,
            },
            {
                path: ':id',
                name: 'unidadeForm',
                component: UnidadeForm,
                props: true,
            },
        ]
    },
    {
        path: '/imunobiologico',
        name: 'imunobiologico',
        component: Imunobiologico,
        props: true,
        children: [
            {
                path: '/',
                name: 'imunobiologicoListing',
                component: ImunobiologicoListing,
                props: true,
            },
            {
                path: ':id',
                name: 'imunobiologicoForm',
                component: ImunobiologicoForm,
                props: true,
            },
        ]
    },
    {
        path: '/diluente',
        name: 'diluente',
        component: Diluente,
        props: true,
        children: [
            {
                path: '/',
                name: 'diluenteListing',
                component: DiluenteListing,
                props: true,
            },
            {
                path: ':id',
                name: 'diluenteForm',
                component: DiluenteForm,
                props: true,
            },
        ]
    },
    {
        path: '/laboratorio',
        name: 'laboratorio',
        component: Laboratorio,
        props: true,
        children: [
            {
                path: '/',
                name: 'laboratorioListing',
                component: LaboratorioListing,
                props: true,
            },
            {
                path: ':id',
                name: 'laboratorioForm',
                component: LaboratorioForm,
                props: true,
            },
        ]
    },
    {
        path: '/numero-lote',
        name: 'lotes',
        component: NumeroLote,
        props: true,
        children: [
            {
                path: '/',
                name: 'numeroLoteListing',
                component: NumeroLoteListing,
                props: true,
            },
            {
                path: ':id',
                name: 'numeroLoteForm',
                component: NumeroLoteForm,
                props: true,
            },
        ]
    },
    {
        path: '/numero-lote-diluente',
        name: 'lotes-diluente',
        component: NumeroLoteDiluente,
        props: true,
        children: [
            {
                path: '/',
                name: 'numeroLoteDiluenteListing',
                component: NumeroLoteDiluenteListing,
                props: true,
            },
            {
                path: ':id',
                name: 'numeroLoteDiluenteForm',
                component: NumeroLoteDiluenteForm,
                props: true,
            },
        ]
    },
    {
        path: '/sala-vacina',
        name: 'sala-vacina',
        component: SalaVacina,
        props: true,
        children: [
            {
                path: '/',
                name: 'salaVacinaListing',
                component: SalaVacinaListing,
                props: true,
            },
            {
                path: ':id',
                name: 'salaVacinaForm',
                component: SalaVacinaForm,
                props: true,
            },
        ]
    },
    {
        path: '/relatorio-perda-fisica',
        name: 'relatorio-perda-fisica',
        component: RelatorioPerdaFisica,
        props: true,
        children: [
            {
                path: '/',
                name: 'RelatorioPerdaFisicaForm',
                component: RelatorioPerdaFisicaForm,
                props: true,
            },
        ]
    },
    {
        path: '/pdf-ocorrencia-imunobiologico',
        name: 'pdf-ocorrencia-imunobiologico',
        component: PDFOcorrenciaImunobiologico,
        props: true,
        children: [
            {
                path: '/',
                name: 'PDFOcorrenciaImunobiologicoForm',
                component: PDFOcorrenciaImunobiologicoForm,
                props: true,
            },
        ]
    },
    {
        path: '*',
        name: 'erro404',
        component: Erro404
    }
]

const router = new VueRouter({
    base: 'template',
    routes,

    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
})

router.beforeResolve((to, from, next) => {
    if($('.modal-open').is(':visible')){
        $('.modal').modal('hide')
    }

    //Bloqueio da tela usuario para usuários diferentes de SUVISA
    if(localStorage.getItem('tipoUsuario') != '1' && to.path.includes('/usuario')) next('/')
    else next()
})

router.afterEach((to, from) => {
    // console.log('afterEach')
})

router.onError(erro => {
    // console.log(erro)
})

export default router
