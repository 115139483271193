import { Interceptor } from "vue2-http"
import Loading from "vue2-loading"

class ProgressBarInterceptor extends Interceptor {

    protected static calls = 0
    public static loading: Loading

    requestOk(response) {
        this.show()
        return response
    }
    
    requestError(error) {
        this.hide()
        return Promise.reject(error)
    }

    responseOk(response) {
        if (response.data.status == 'finalizado') {
            this.hide()
        }
        return response
    }
    
    responseError(error) {
        this.hide()
        return Promise.reject(error)
    }

    protected show() {
        ProgressBarInterceptor.loading.show()
    }
    
    protected hide() {
        ProgressBarInterceptor.loading.hide()
    }

}

export default ProgressBarInterceptor