import { Vue, Component } from "vue-property-decorator"
import 'vue-class-component/hooks'
import PageMixin from "vue2-page-mixin"

interface Home extends PageMixin {}

@Component({
    mixins: [PageMixin],
})
class Home extends Vue implements Home {

    createData() {
        return {
            nome: ''
        }
    }
}

export default Home