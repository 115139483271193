import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import store from '../../store'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import { VueSelect } from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Messages from 'vue2-messages'
import fmt from '../../helpers/fmt'
import NumeroLote from '../../models/numeroLote'

interface NumeroLoteForm extends PageMixin {}

@Component({
    components: {'v-select': VueSelect},
    mixins: [PageMixin],
    store,
})
class NumeroLoteForm extends Vue implements NumeroLoteForm {
    fmt = fmt

    name = 'NumeroLoteForm';

    model = new NumeroLote();

    imunobiologicos = [];
    laboratorios = [];
       
    idNumeroLote = null;
    tipoUsuario = {}
    
    messages = {
        model: this.model,
    };

    change() {

    }

    computed(){

    }
    
    created() {
        this.idNumeroLote = this.$route.params.id == "new" ? null : this.$route.params.id
        if(this.idNumeroLote == null){
            this.model = new NumeroLote();
        }
        this.inicializarObjetos();
        this.getNumeroLote();
    }

    mounted() {

    }

    getNumeroLote(){
        if(this.idNumeroLote != null){
            this.http().get(`${this.serverUrl}/numero-lote/get-numero-lote/${this.idNumeroLote}`)
            .then(res => {
                if(res.data.erro){
                    this.$swal.fire({title: res.data.msg+' - Redirecionando para a listagem', text: '', icon: 'error', timer: 2700});
                    setTimeout(() => {
                        this.$router.push('/numero-lote');
                    } , 3000);
                    return;
                }
                this.model = res.data;
            });
        }
    }

    inicializarObjetos(){
        this.http().get(`${this.serverUrl}/numero-lote/inicializar-objetos/`).then(resp => {
            this.imunobiologicos = resp.data.imunobiologicos;
            this.laboratorios = resp.data.laboratorios;
        });
    }

    salvar() {
        if(this.model.numeroLote == null){
            Messages.error('Por favor, preencha o campo Número Lote.');
            $("#descricao").trigger('focus');
            return;
        }
        if(this.model.idImunobiologico == null){
            Messages.error('Por favor, selecione o Imunobiológico.');
            $("#idImunobiologico").trigger('focus');
            return;
        }
        if(this.model.idLaboratorioProdutor == null){
            Messages.error('Por favor, selecione o Laboratório Produtor.');
            $("#idLaboratorioProdutor").trigger('focus');
            return;
        }
        this.http().post(`${this.serverUrl}/numero-lote/salvar`, {
            model: this.model
         })
        .then(resp => {
            if(resp.data.error){
                Messages.error(resp.data.error)
                this.$swal.fire('Erro!', '', 'error')
                return;
            }
            if (resp.data != null) {
                if(this.$route.params.id == "new"){
                    var msg = "Dados Salvo com Sucesso!";
                    this.limpar();
                }else{
                    var msg = "Dados Editados com Sucesso!";
                }
                Messages.success(msg)
                this.$swal.fire('Sucesso!', '', 'success')
                this.$swal.fire({title: 'Sucesso!', text: '', icon: 'success', timer: 1250});
            } else {
                Messages.success('Os dados não foram salvos!')
                this.$swal.fire('Erro!', '', 'error')
            }
        }).catch(() => {});
    }

    limpar(){
        this.model = new NumeroLote();
    }

}

export default NumeroLoteForm