













    export default {
        name: 'Footer',
        data() {
            return {}
        }
    }
