




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import PerdaFisicaForm from "./PerdaFisicaForm.script";
import hmr from "vue2-hmr/dist";

export default hmr(PerdaFisicaForm, module);
