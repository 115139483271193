import TipoImunobiologico from "./tipoImunobiologico"

class Imunobiologico {
    id : null
    idTipoImunobiologico: null
    tipoImunobiologico: TipoImunobiologico = new TipoImunobiologico()
    descricao : null
    excluido : null
}

export default Imunobiologico