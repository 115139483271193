


























































































import ImunobiologicoListing from "./ImunobiologicoListing.script";
import hmr from "vue2-hmr/dist";

export default hmr(ImunobiologicoListing, module);
