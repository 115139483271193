


































import DiluenteForm from "./DiluenteForm.script";
import hmr from "vue2-hmr/dist";

export default hmr(DiluenteForm, module);
