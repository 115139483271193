import Imunobiologico from "./imunobiologico"
import NumeroLote from "./numeroLote"
import PerdaFisica from "./perdaFisica"

class MaterialPerdaFisica {
    id : null = null
    idPerdaFisica : null = null
    perdaFisica : PerdaFisica = new PerdaFisica()
    idLaboratorioProdutor : null = null
    idImunobiologico : null = null
    imunobiologico : Imunobiologico = new Imunobiologico()
    idNumeroLote : null = null
    numeroLote: NumeroLote = new NumeroLote()
    prazoValidadeFrasco : null = null
    qtdFrasco : null = null
    qtdDose : null = null
    idTipoApresentacao : null = null
    dataRecebimento : null = null
    prazoValidade : null = null
    tempAtualVacina : null = null
    idDiluente : null = null
    idLaboratorioDiluente : null = null
    idTipoApresentacaoDiluente : null = null
    idNumeroLoteDiluente : null = null
    numeroLoteDiluente: NumeroLote = new NumeroLote()
    dataRecebimentoDiluente : null = null
    qtdFrascoDiluente : null = null
    qtdDoseDiluente : null = null
    tempAtualDiluente : null = null
    idFormaFarmaceutica : null = null
    tempArmAtualImunobiologico : null = null
    excursaoAnterior : boolean | null = null
    excursaoAnteriorData : null = null
    excursaoAnteriorTempMax : null = null
    excursaoAnteriorTempMin : null = null
}

export default MaterialPerdaFisica