import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import { VueSelect } from 'vue-select'
import Messages from 'vue2-messages'
import 'vue-select/dist/vue-select.css'

interface PDFOcorrenciaImunobiologicoForm extends PageMixin {}

@Component({
    components: {'v-select': VueSelect,},
    mixins: [PageMixin],
})
class PDFOcorrenciaImunobiologicoForm extends Vue implements PDFOcorrenciaImunobiologicoForm {
    
    filtro = {
        tipoOcorrencia: '',
    }

    created() {
        this.inicializarObjetos()
    }

    tipoOcorrencias = [];

    inicializarObjetos(){
        this.http().get(`${this.serverUrl}/perda-fisica/inicializar-pdf-ocorrencia-imunobiologico/`).then(resp => {
            this.tipoOcorrencias = resp.data.tipoOcorrencias;
        });
    }

    limpar(){
        Vue.set(this.filtro, 'tipoOcorrencia', '');
    }

    gerarPDF() {
        if (!this.filtro.tipoOcorrencia) {
            Messages.error('Selecione um Tipo de Ocorrência para gerar o PDF');
            return;
        } else {
            this.http().post(`${this.serverUrl}/perda-fisica/pdf-ocorrencia-imunobiologico`, { ...this.filtro }).then(res => {
                let byteCharacters = atob(res.data);
                let byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                let byteArray = new Uint8Array(byteNumbers);
                let file = new Blob([byteArray], { type: 'application/pdf' });
                let fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
        }
    }
}
export default PDFOcorrenciaImunobiologicoForm