import $ from 'jquery'
import { Component, Vue } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import store from '../../store'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import Messages from 'vue2-messages'
import { VueSelect } from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Pagination from '../../components/Pagination.vue'
import fmt from '../../helpers/fmt'
import NumeroLote from '../../models/numeroLote'
import SalaVacina from '../../models/salaVacina'

interface SalaVacinaListing extends PageMixin {}

@Component({
    components: {'v-select': VueSelect, 'paginacao': Pagination},
    mixins: [PageMixin],
    store
})
class SalaVacinaListing extends Vue implements SalaVacinaListing {
    fmt = fmt

    name = 'SalaVacinaListing'

    salasDeVacinas = [];
    unidades = [];
    tipoUsuario = {};

    model = new SalaVacina();

    filtro = {
        idUnidade: null,
        nomeSalaVacina: null,
        cnesSalaVacina: null,
        excluido : false
    }
    
    limit = 10
    limits = [10, 20, 50, 100]
    page = 1
    totalPages = 0
    pagination = {totalCount: 1}

    pageChanged (page) {            
        this.page = page
        this.filter(page);
    }

    limitSelect(){
        this.page = 1
        this.buscarSalasDeVacinas();
    }

    created() {
        this.salasDeVacinas = [];

        this.inicializarDadosFiltros();
        this.limpar();
    }

    mounted() {
        
    }

    inicializarDadosFiltros(){
        this.http().get(`${this.serverUrl}/unidade/listar-unidades`)
        .then(res => {
            this.unidades = res.data.unidades;
        })
    }

    filter(page) {
        this.http().get(`${this.serverUrl}/sala-vacina/filter?filtros=${JSON.stringify(this.filtro)}&page=${page}&per-page=${this.limit}`)
            .then(res => {
                this.salasDeVacinas = res.data.salasDeVacinas
                this.pagination = res.data?.pagination
                this.totalPages = Math.ceil(this.pagination?.totalCount / this.limit)
                $('[data-toggle="tooltip"]').tooltip()
            })
    }

    buscarSalasDeVacinas() {
        return this.http().get(`${this.serverUrl}/sala-vacina/${this.page}/${this.limit}`);
    }

    limpar(){
        this.filtro = {
            idUnidade: null,
            nomeSalaVacina: null,
            cnesSalaVacina: null,
            excluido : false
        };
        this.filter(1);
    }

    confirmarAcao(id, tipoAcao) {
        var mensagemFalha = "Ocorreu uma falha ao executar a ação";
        if(tipoAcao == 'excluir'){
            var mensagem = "Tem certeza que deseja Excluir esta Sala de Vacina?";
            var mensagemSucesso = "Exclusão realizada com sucesso";
        }else if(tipoAcao == 'recuperar'){
            var mensagem = "Tem certeza que deseja Recuperar esta Sala de Vacina?";
            var mensagemSucesso = "Recuperação realizada com sucesso";
        }

        this.mensagemConfirmacao(
            mensagem, 
            'Faça sua escolha', 
            mensagemSucesso, 
            this.executarAcao.bind(null, mensagemSucesso, mensagemFalha, tipoAcao, id)
          )
    }
    
    executarAcao(mensagemSucesso, mensagemFalha, tipoAcao, id) {
        this.http().post(`${this.serverUrl}/sala-vacina/excluir-recuperar`, {id: id, tipo: tipoAcao})
            .then(resp => {
                if (resp.data != null) {
                    Messages.success(mensagemSucesso)
                    this.filter(this.page)
                    this.$swal.fire(resp.data.msg, '', 'success')
                } else {
                    Messages.success(mensagemFalha)
                }
            })
            .catch(() => {})
    }

    mensagemConfirmacao(titulo, corpoMensagem, mensagemPosConfirmacao, callback){
        this.$swal({
          title: titulo,
          html: corpoMensagem,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Ok`,
          icon: 'warning'
        }).then((result) => {
          if (result.isConfirmed) {
            callback()
          } else if (result.isDismissed) {
            this.$swal.fire('Operação cancelada', '', 'info')
          }
        })
    }
}

export default SalaVacinaListing