
































import DropdownTree from "./DropdownTree.vue";
import HttpMixin from 'vue2-http-mixin';
import PathMixin from 'vue2-path-mixin';

export default {
  components: {
    "dropdown-tree": DropdownTree,
  },

  mixins:[HttpMixin, PathMixin],

  name: "Menu",

  data() {
    return {
      activeItem: 1,
      tipoUsuario: {},
      menu: {
        1: {
          nome: "Usuários",
          link: '/usuario',
          permissoes: ['1', '5'],
          icon: 'fas fa-user'
        },
        2: {
          nome: "Ocorrência em Imunobiológico",
          link: '/perda-fisica',
          permissoes: ['1', '2', '3', '4', '5'],
          icon: 'fas fa-syringe'
        },
        3: { nome: "Cadastros Básicos",
          permissoes: ['1', '2', '3', '5'],
          submenu: [
            { nome: "Unidades", link: '/unidade', permissoes: ['1', '2', '3', '5'] },
            { nome: "Imunobiológicos", link: '/imunobiologico', permissoes: ['1', '5'] },
            { nome: "Diluentes", link: '/diluente', permissoes: ['1', '5'] },
            { nome: "Laboratórios", link: '/laboratorio', permissoes: ['1', '5'] },
            { nome: "Lotes dos Imunobiológicos", link: '/numero-lote', permissoes: ['1', '5'] },
            { nome: "Lotes dos Diluentes", link: '/numero-lote-diluente', permissoes: ['1', '5'] },
            { nome: "Salas de Vacinas", link: '/sala-vacina', permissoes: ['1', '2', '3', '5'] },
          ]
        },
        4: {
          nome: "Relatório",
          permissoes: ['1', '2', '3', '4', '5'],
          submenu: [
            { nome: "Ocorrência em Imunobiológico", link: '/relatorio-perda-fisica', permissoes: ['1', '2', '3', '5'] },
            { nome: "Formulário PDF Ocorrência em Imunobiológico", link: '/pdf-ocorrencia-imunobiologico', permissoes: ['1', '5'] },
          ]
        }
      },
    };
  },
  mounted() {
    // $(".submenu").parent().attr("style", "left: 225px !important; top: 0px !important; position: absolute !important; display: none;");
    window.addEventListener('localstorage-usuario', (event: any) => {
        this.tipoUsuario = event.detail.tipoUsuario;
    });
  },

  created(){
    this.tipoUsuario = localStorage.getItem('tipoUsuario')
  },

  methods: {
    sair(){
      localStorage.setItem('auth', 'false')
      window.dispatchEvent(new CustomEvent('localstorage-changed', {
        detail: {
          storage: localStorage.getItem('auth')
        }
      }))

      localStorage.setItem('tipoUsuario', '{tipo: null, id: null, nome: null}')
      window.dispatchEvent(new CustomEvent('localstorage-tipoUsuario', {
        detail: {
          tipoUsuario: localStorage.getItem('tipoUsuario')
        }
      }))
    },

    temPermissaoAcesso(listaPermissoes){
      for(var permissao of listaPermissoes){
        if(permissao == this.tipoUsuario){
          return true
        }
      }
      return false
    }
  }
};
