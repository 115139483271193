import Municipio from "./municipio"

class Unidade {
    id: null
    chvmnc : null
    municipio : Municipio = new Municipio()
    cnes : null
    nome : null
    endereco : null
    contato : null
    excluido : boolean
    enviarMunicipio: boolean
    enviarRegional: boolean
}

export default Unidade