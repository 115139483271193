















































import SalaVacinaForm from "./SalaVacinaForm.script";
import hmr from "vue2-hmr/dist";

export default hmr(SalaVacinaForm, module);
