























































































import LaboratorioListing from "./LaboratorioListing.script";
import hmr from "vue2-hmr/dist";

export default hmr(LaboratorioListing, module);
